<template>
  <el-result icon="info" title="404">
    <template #sub-title>
      <p>Sorry, request error</p>
    </template>
  </el-result>
</template>
<script setup lang="ts"></script>

<style scoped></style>
